<template>
  <FilterChips
    name="Квалификация"
    :items="items"
  />
</template>

<script setup>
import { getSkillLevels } from '~/api/skills'

const props = defineProps({
  items: Array
})

const { data: items } = await useAsyncData(async () => props.items ?? await getSkillLevels(), { watch: [() => props.items] })
</script>
