<template>
  <FilterChips
    select
    name="Занятость"
    :items="items"
  />
</template>

<script setup>
import { busynessTypes } from '~/data/project'

const props = defineProps({
  items: Array
})

const items = computed(() => props.items ?? busynessTypes)
</script>
